@charset "utf-8";
/* CSS Document */
html,
body {
	font-size: 16px;
}
body {
	font-family: Arial, Helvetica, sans-serif;
}
/*developer End css*/
.error {
	color: #ff0000;
	font-size: 0.81rem;
}
.errorControl {
	color: #bc0000;
	background-color: #ffcccc;
	border-color: #ff9999 !important;
	outline: 0;
}
/*developer End css*/
/*Header*/
.masterHead {
	padding: 9px 35px;
	/* background-color: #e5e5e5; */
	border-bottom: 1px solid #e9e9e9;
}

.staticContent {
	background-color: #f1f3f4;
}

.staticContent .becomeLayer5 {
	background-color: #ffffff;
}

.navigationContainer {
	text-align: right;
}
.navigationContainer ul {
	margin: 0;
	padding: 0;
}
.navigationContainer ul li {
	list-style-type: none;
	display: inline-block;
	font-size: 1rem;
	margin-right: 1rem;
}
.navigationContainer ul li:last-child {
	margin-right: 0;
}
.navigationContainer ul li a.login {
	color: #625d5d;
}
.navigationContainer ul li a.login:hover {
	color: #838080;
}
.navigationContainer ul li a.signUp {
	color: #fff;
	background: #009fd9;
	padding: 0.37rem 1.12rem;
	border-radius: 0.25rem;
}
.navigationContainer ul li a.signUp:hover {
	background: #0095cb;
	text-decoration: none;
}
/*Header*/
/*Login Section*/
.loginContainer {
	background: #eff1f3;
	padding: 5rem 0;
}
.loginContainer .formContainer {
	background-color: #ffffff !important;
}

.formContainer label {
	font-size: 0.81rem;
	color: #464955;
	font-weight: bold;
	margin-bottom: 0.25rem;
}
.formContainer .form-group {
	margin-bottom: 0.25rem;
}
.formContainer .form-control {
	border: 1px solid #aba7a7;
	font-size: 0.9rem;
}
.formContainer .forgotPass {
	color: #8a8a8a;
	font-size: 0.9rem;
	/* font-weight: bold; */
}
.formContainer .form-check-input {
	margin-top: 0.4rem;
}

.formContainer .custom-control-label {
	font-size: 0.9rem;
	font-weight: normal !important;
}

.formContainer {
	background: transparent !important;
}

.formButtonContainer {
	margin-top: 0.85rem;
	margin-bottom: 1.15rem;
}
.cutmBtnLogin {
	background: #009fd9 !important;
	font-size: 0.81rem;
	font-weight: bold;
}
.signUpLink {
	color: #201f1f;
	font-size: 0.94rem;
	font-weight: bold;
}
.signUpLink a {
	color: #009fd9;
}
/*Login Section*/
/*Create account Section*/
.termsConditionTxt {
	font-size: 0.81rem;
	color: #201f1f;
	font-weight: bold;
	margin: 0.5rem 0;
	text-align: center;
}
.termsConditionTxt a {
	color: #009fd9;
}
/*Create account Section*/
/*footer Section*/
.footer {
	padding: 3rem 0;
	background-color: #e5e5e5;
}
.footerLogo {
	margin-bottom: 1rem;
}
.footerLinksSection .heading {
	color: #080909;
	font-size: 1rem;
	margin-bottom: 1rem;
	font-weight: bold;
}
.footerLinksSection ul {
	margin: 0;
	padding: 0;
}
.footerLinksSection ul li {
	list-style-type: none;
	display: block;
	font-size: 0.9rem;
}
.footerLinksSection ul li a {
	color: #686969;
}
.footerLinksSection ul li a:hover {
	color: #3d3d3d;
}
/*footer Section*/
/*Navigation*/
.borderBtm {
	border-bottom: 1px solid #d9dce3;
}
.navigationContainerResponsive .navbar {
	padding: 0;
}
.navigationContainerResponsive .nav-link {
	padding: 0;
}
.navigationContainerResponsive .navbar-light .navbar-nav .active > .nav-link,
.navigationContainerResponsive .navbar-light .navbar-nav .nav-link.active,
.navigationContainerResponsive .navbar-light .navbar-nav .nav-link.show {
	font-weight: bold;
	text-decoration: underline;
}
.navigationContainerResponsive ul > li > a.signUp {
	color: #fff;
	background: #009fd9;
	padding: 0.37rem 1.12rem;
	border-radius: 0.25rem;
	margin-right: 0.5rem;
}
.navigationContainerResponsive ul > li > a.signUp:hover {
	background: #0095cb;
	text-decoration: none;
}
/*Navigation*/
/*Project details*/
.projectsDetailsContainer {
	margin: 3rem 0;
}

h5 {
	font-weight: bold;
	margin-bottom: 1rem;
	border-bottom: 1px solid #009fd9;
	padding-bottom: 0.5rem;
}

.projectsDetailsContainer h5 {
	font-weight: bold;
	margin-bottom: 1rem;
}
.projectsDetailsContainer a {
	color: #009fd9;
}
.projectsDetailsContainer a.btnCancel {
	font-size: 0.95rem;
	color: #fff;
	background: #009fd9;
	padding: 0.37rem 1.12rem;
	border-radius: 0.25rem;
}
.projectsDetailsContainer a.btnCancel:hover {
	background: #0095cb;
	text-decoration: none;
}
.relatedProjDetailsContainer h5 {
	margin-bottom: 1rem;
	border-bottom: 1px solid #009fd9;
	padding-bottom: 0.5rem;
}
.repeatProjDetailsContainer {
	margin: 1.31rem 0;
}
.repeatProjDetailsContainer .projName {
	font-size: 0.95rem;
	color: #080909;
	font-weight: bold;
}
.repeatProjDetailsContainer .projRating {
	color: #2db682;
	font-weight: bold;
	font-size: 0.8rem;
}
.repeatProjDetailsContainer .projRating span.comments {
	color: #787f87;
	font-weight: normal;
}
.repeatProjDetailsContainer .projDetails {
	color: #010101;
	font-size: 0.85rem;
}
.repeatProjDetailsContainer a.btnViewProfile {
	font-size: 0.95rem;
	color: #fff;
	background: #009fd9;
	padding: 0.37rem 1.12rem;
	border-radius: 0.25rem;
	display: block;
}
.repeatProjDetailsContainer a.btnViewProfile:hover {
	background: #0095cb;
	text-decoration: none;
}

.homepageBgContainer {
	display: flex;
	flex-wrap: wrap;
	align-content: flex-end;
	padding-bottom: 2.5rem;
}
/*Project details*/

/*Search Result*/
.searchResultContainer {
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: 10rem;
}

.searchResultContainerForm {
	width: 100%;
	background-color: #fff;
}

.searchResultContainerForm .searchForm {
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
	-moz-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.searchResultContainerForm .searchForm .input-field {
	height: 50px;
}
.searchResultContainerForm .searchForm .input-field input {
	height: 100%;
	background: #fff;
	display: block;
	width: 100%;
	padding: 8px;
	font-size: 1rem;
	color: #737476;
	border: 1px solid #e7ebec;
	outline: 0;
}
.searchResultContainerForm .searchForm .input-field input.iconMap {
	background: url("../../images/iconMap.png") no-repeat 12px center;
	padding-left: 30px;
	color: #303032;
	font-weight: bold;
}
.searchResultContainerForm .searchForm .firstWrap {
	-ms-flex-positive: 1;
	flex-grow: 1;
}
.searchResultContainerForm .searchForm .secondWrap {
	width: 140px;
	background-color: #ffffff;
}
.searchResultContainerForm .searchForm .thirdWrap {
	width: 95px;
}
.searchResultContainerForm .searchForm .thirdWrap .btn-search {
	height: 100%;
	width: 100%;
	white-space: nowrap;
	font-size: 1rem;
	color: #fff;
	border: 0;
	cursor: pointer;
	position: relative;
	z-index: 0;
	background: #019dd8;
	transition:
		all 0.2s ease-out,
		color 0.2s ease-out;
	outline: 0;
	font-weight: bold;
}
.searchResultContainerForm .searchForm .thirdWrap .btn-search:hover {
	background: #0095cb;
}

.btn-search {
	height: 50px;
	width: 100%;
	white-space: nowrap;
	font-size: 1rem;
	color: #fff;
	border: 0;
	cursor: pointer;
	position: relative;
	z-index: 0;
	background: #019dd8;
	transition:
		all 0.2s ease-out,
		color 0.2s ease-out;
	outline: 0;
	font-weight: 700;
}

.btn-search:hover {
	background: #0095cb;
}

.editPhoto {
	position: absolute;
}

.btnCancel {
	font-size: 0.81rem;
	font-weight: bold;
	color: #fff;
	border: 1px solid #adb5bd;
	background-color: #aba7a7;
}

.searchForm .autoComplete svg .MuiSvgIcon-fontSizeMedium {
	display: none;
}

/*Search Result*/
/*Search Container*/
.searchContainer {
	background: url("../../images/homeBgImg.jpg") no-repeat;
	background-size: cover;
	background-position: left top;
	height: 71vh;
}
.searchContainerForm h4 {
	font-weight: bold;
	color: #37383a;
	margin-top: 1rem;
	text-align: center;
}
.searchContainerForm .searchForm {
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
	-moz-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.searchContainerForm .searchForm .input-field {
	height: 50px;
}
.searchContainerForm .searchForm .input-field input {
	height: 100%;
	background: #fff;
	display: block;
	width: 100%;
	padding: 8px;
	font-size: 1rem;
	color: #737476;
	border: 1px solid #e7ebec;
	outline: 0;
}
.searchContainerForm .searchForm .input-field input.iconMap {
	background: url("../../images/iconMap.png") no-repeat 12px center;
	padding-left: 30px;
	color: #303032;
	font-weight: bold;
}
.searchForm .firstWrap {
	-ms-flex-positive: 1;
	flex-grow: 1;
}
.searchForm .secondWrap {
	width: 140px;
}
.searchForm .thirdWrap {
	width: 95px;
}
.searchForm .thirdWrap .btn-search {
	height: 100%;
	width: 100%;
	white-space: nowrap;
	font-size: 1rem;
	color: #fff;
	border: 0;
	cursor: pointer;
	position: relative;
	z-index: 0;
	background: #019dd8;
	transition:
		all 0.2s ease-out,
		color 0.2s ease-out;
	outline: 0;
	font-weight: bold;
}
.searchForm .thirdWrap .btn-search:hover {
	background: #0095cb;
}

.searchContainerForm a.btnStartProj {
	font-size: 1.5rem;
	color: #fff;
	background: #009fd9;
	padding: 0.37rem 1.12rem;
	border-radius: 0.25rem;
	display: block;
	margin-top: 1rem;
	text-align: center;
}
.searchContainerForm a.btnStartProj:hover {
	background: #0095cb;
	text-decoration: none;
}
/*Search Container*/

/*Inbox*/
.inboxContainer {
	padding: 3rem 0;
}
.inboxContainer h5 {
	font-weight: bold;
	margin-bottom: 1rem;
	border-bottom: 1px solid #009fd9;
	padding-bottom: 0.5rem;
}
.repeatInboxContainer {
	margin: 1.31rem 0;
	border-radius: 4px;
	border: 1px solid #f5f5f5;
	padding: 1.6rem;
	-webkit-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
	-moz-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
}
.repeatInboxContainer .subject {
	font-size: 0.95rem;
	color: #080909;
	font-weight: bold;
}
.repeatInboxContainer .time {
	font-size: 0.85rem;
	color: #9fa2b5;
	font-weight: bold;
}
.repeatInboxContainer .title {
	font-size: 0.85rem;
	color: #9fa2b5;
	margin-bottom: 0.5rem;
}
.repeatInboxContainer .message {
	color: #010101;
	font-size: 0.85rem;
}
/*Inbox*/

/*Account Settings*/
.accountSettingContainer {
	padding: 2rem 0;
	background-color: #eff1f3;
}
.accountSettingContainer h5 {
	font-weight: bold;
	margin-bottom: 1rem;
	border-bottom: 1px solid #009fd9;
	padding-bottom: 0.5rem;
}
#accountSettingDetails .acctDeleteTxt {
	margin-bottom: 0.5rem;
	margin-top: 0.5rem;
	color: #6d6d6d;
}
.accountNavigation .nav-pills .nav-link.active {
	background: #009fd9;
	cursor: default;
}
.accountNavigation .nav-link {
	color: #009fd9;
	cursor: pointer;
}

.headingTxt {
	font-size: 1rem;
	color: #000;
	font-weight: bold;
	/* margin-bottom: 1rem; */
	/* background: #eff1f3; */
}

.popularServicesSection {
	background-color: #019dd8;
	/* min-height: 35vh; */
	padding-top: 3rem;
	padding-bottom: 3rem;
	color: #ffffff;
}

.popularServicesText {
	margin: auto;
	max-width: 65rem;
	font-size: 2rem;
}

.popularServicesViewAllButton {
	border: 0.15rem solid #ffffff;
	background: #019dd8;
	color: #ffffff;
	font-size: 1.5rem;
	padding: 0.3rem 2rem 0.3rem 2rem;
	border-radius: 15px;
}

.popularServicesSpan2 {
	font-size: 1.9rem;
}

.becomeAProSection {
	/* min-height: 45vh; */
	/* padding-top: 5rem; */
	margin: auto;
	max-width: 52rem;
	padding-top: 3rem;
	padding-bottom: 3rem;
}

.becomeAProSection .layerOne {
	padding-top: 5rem;
}

.becomeAProSection .layerOne,
.becomeLayer1 .layerOne,
.becomeLayer2 .layerOne,
.becomeLayer3 .layerOne,
.becomeLayer11 .layerOne,
.becomeLayer5 .layerOne {
	font-size: 1.6rem;
	font-weight: 600;
}

.becomeAProSection .layerTwo,
.becomeLayer1 .layerTwo,
.becomeLayer11 .layerTwo,
.becomeLayer2 .layerTwo,
.becomeLayer3 .layerTwo,
.becomeLayer5 .layerTwo {
	font-size: 1.15rem;
}

.becomeLayer2 .layerTwo,
.becomeLayer3 .layerTwo {
	max-width: 50rem;
	margin: auto;
}

.becomeLayer2 .counter {
	color: #009ed8;
	font-weight: 600;
}

.becomeAProSection .signUp,
.becomeLayer1 .signUp,
.becomeLayer11 .signUp,
.becomeLayer5 .signUp {
	color: #fff;
	background: #009fd9;
	padding: 0.37rem 1.12rem;
	border-radius: 0.25rem;
	margin-right: 0.5rem;
	border: none;
}

.becomeLayer1 {
	min-height: 60vh;
	margin: auto;
	background-color: #ffca00;
	padding-top: 2rem;
	padding-bottom: 2rem;
}

.becomeLayer11 {
	min-height: 59vh;
	margin: auto;
	background-color: #ffca00;
	padding-top: 2rem;
}

.becomeLayer2 {
	/* min-height: 25vh; */
	margin: auto;
	background-color: #eaeaea;
	padding-top: 2rem;
	padding-bottom: 2rem;
}

.becomeLayer3 {
	/* min-height: 25vh; */
	margin: auto;
	padding-top: 2rem;
	padding-bottom: 2rem;
}

.becomeLayer4 {
	/* min-height: 32vh; */
	margin: auto;
	background-color: #009ed8;
	color: #ffffff;
	padding-top: 2rem;
	padding-bottom: 2rem;
}

.becomeLayer4Container {
	max-width: 50rem;
	margin: auto;
}

.becomeLayer4 .layerOne {
	text-decoration: underline;
	font-size: 1.8rem;
}

.becomeLayer4 .layerTwo {
	font-size: 2.5rem;
	font-weight: 600;
}

.becomeLayer4 .layerThree {
	font-size: 1.15rem;
}

.becomeLayer5 {
	margin: auto;
	max-width: 65rem;
	/* min-height: 45vh; */
}

.becomeLayer1 .layerOne,
.becomeLayer11 .layerOne,
.becomeLayer5 .layerOne {
	padding-top: 10rem;
}

.become_growth {
	background: url("../../images/become_layer1.PNG") no-repeat;
	background-size: contain;
	min-height: 59vh;
}

.about_us {
	background: url("../../images/aboutus.jpeg") no-repeat;
	background-size: cover;
	min-height: 90vh;
	margin: auto;
}

.howToUse {
	background: url("../../images/howtouse.jpeg") no-repeat;
	background-size: cover;
	min-height: 90vh;
	margin: auto;
}

.howItWorks {
	background: url("../../images/howitworks.jpeg") no-repeat;
	background-size: cover;
	min-height: 90vh;
	margin: auto;
}

.cardCotent {
	border-radius: 15px;
	background-color: #fff;
}

.become_layer4 {
	background: url("../../images/become_layer4.PNG") no-repeat;
	background-size: contain;
	min-height: 36vh;
}

.pt-10 {
	padding-top: 10rem;
}

.bt-0 {
	border-top: none !important;
}

.bb-0 {
	border-bottom: none !important;
}

.bl-0 {
	border-left: none !important;
}

.br-0 {
	border-right: none !important;
}

.b-0 {
	border: none !important;
}

.truncate {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.minHeightDialogContainer {
	min-height: 20rem;
}

.subHeadingTxt {
	font-size: 1rem;
	color: #000;
	font-weight: 600;
	background-color: #f8f9fa;
	margin-bottom: 0.5rem;
}

.passwordContainer .headingTxt {
	font-size: 1rem;
	color: #000;
	font-weight: 600;
	margin-bottom: 1rem;
}

.notificationContainer .headingTxt {
	font-size: 1rem;
	color: #000;
	font-weight: 600;
	margin-bottom: 1rem;
}
.notificationContainer .notificationMessage {
	background: #f9f9fb;
	padding: 0.5rem;
	font-size: 0.75rem;
	font-weight: 600;
}
.notificationContainer .custColor {
	color: #307bf2;
}
.switchControlContainter {
	margin-top: 1.5rem;
	border-left: 1px solid #9f9f9f;
	border-right: 1px solid #9f9f9f;
	border-bottom: 1px solid #9f9f9f;
}
.switchControlContainter .box {
	border-top: 1px solid #9f9f9f;
	padding: 0.25rem 0.5rem;
	font-size: 0.8rem;
}
.switchControlContainter .box .custom-switch {
	padding-left: 0;
}
.switchControlContainter .box span {
	margin-top: 4px;
}
/*accordian css*/
#accountSettingDetails .card {
	/*margin-bottom: 1rem;*/
	border: 0;
	border-radius: 0;
}
#accountSettingDetails .card:last-child {
	border-bottom: 1px solid #9f9f9f;
}
#accountSettingDetails .card:nth-child(4) {
	border-bottom: 1px solid #9f9f9f;
}
#accountSettingDetails .card .card-header {
	border: 0;
	-webkit-box-shadow: 0 0 20px 0 rgba(213, 213, 213, 0.5);
	box-shadow: 0 0 20px 0 rgba(213, 213, 213, 0.5);
	border-radius: 2px;
	padding: 0;
}

#accountSettingDetails .card .card-header .btn-header-link {
	display: block;
	text-align: left;
	/*background: #FFE472;*/
	color: #222;
	/*padding: 20px;*/
	border: 1px solid #9f9f9f;
	border-bottom: 0px;
	border-radius: 0;
	outline: 0;
}

#accountSettingDetails .card .card-header .btn-header-link:after {
	content: "\f107";
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
	float: right;
}

#accountSettingDetails .card .card-header .btn-header-link.collapsed {
	/* background: #A541BB;*/
	color: #6d6b6c;
	border: 1px solid #9f9f9f;
}

#accountSettingDetails .card .card-header .btn-header-link.collapsed:after {
	content: "\f106";
}

#accountSettingDetails .card .collapsing {
	/*background: #FFE472;*/
	line-height: 30px;
}

#accountSettingDetails .card .collapse {
	border: 0;
}

#accountSettingDetails .card .collapse.show {
	/*background: #FFE472;
  line-height: 30px;
  color: #222;*/
	border-left: 1px solid #9f9f9f;
	border-right: 1px solid #9f9f9f;
}

.css-19kzrtu {
	padding: 0px !important;
}

.Mui-disabled {
	opacity: unset !important;
}

/* .MuiTextField-root {
	background-color: #ffffff !important;
} */

.paymentIcon {
	width: 50px;
	height: auto;
}

.hoverEdit a {
	visibility: hidden;
}
.hoverEdit:hover a {
	visibility: visible;
}

.modalTitle {
	background-color: #e9ecef !important;
}

.tabPanel {
	border: 1px solid #c7ccd1;
	/* margin-top: 1rem; */
	padding: 7px 10px;
	/* border-radius: 5px; */
	background: #ffffff;
}

.portfolioMediaCard {
	position: relative;
	cursor: pointer;
}

.portfolioProjectCard {
	position: relative;
}

.portfolioProjectCard a {
	cursor: pointer;
}

.portfolioMediaCardMedia {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
}

.boxPortfolioMedia {
	flex-grow: 1;
	max-height: 393px;
	overflow-y: auto;
}

.dollarIconMap {
	background: url("../../images/dollarIconMap.png") no-repeat 10px center #ffffff;
	padding-left: 30px;
	color: #303032;
}

.inputfile {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.inputfile-1 + label {
	color: #fff;
	background-color: #009fd9;
}

.inputfile + label {
	max-width: 80%;
	font-size: 1.25rem;
	font-weight: 700;
	text-overflow: ellipsis;
	white-space: nowrap;
	cursor: pointer;
	/* display: inline-block; */
	overflow: hidden;
	/* padding: 0.625rem 1.25rem; */
}

.mapContainer {
	min-height: 500px;
}

.breadCrumb {
	font-size: 13px;
}

.searchSection {
	box-shadow: 0 4px 4px 0 rgb(0 0 0 / 25%);
}

.height50 {
	height: 50px;
}

.autoComplete .MuiFormControl-fullWidth {
	border: 1px solid #e7ebec;
	border-bottom: none;
}

.autoComplete .MuiFormControl-fullWidth fieldset {
	border: none;
	border-radius: unset;
	border-style: none;
	border-width: 0px;
}

.autoComplete .MuiFormControl-fullWidth .MuiAutocomplete-inputRoot {
	height: 50px;
}

.autoComplete .MuiFormControl-fullWidth .MuiAutocomplete-inputRoot input {
	border: none !important;
	height: 50px;
	display: block;
	width: 100%;
	font-size: 1rem;
	color: #737476;
	outline: 0;
}

.zipCodeGoogle {
	height: 50px;
	background: #fff;
	display: block;
	width: 100%;
	padding: 8px;
	font-size: 1rem;
	color: #737476;
	border: 1px solid #e7ebec;
	outline: 0;
}

.iconMap {
	background: url("../../images/iconMap.png") no-repeat 12px center;
	padding-left: 30px;
	color: #303032;
	font-weight: bold;
}

.twitterIcon {
	background: url("../../images/twitterIcon.PNG") no-repeat 5px center;
	padding-left: 40px;
	color: #303032;
}

.facebookIcon {
	background: url("../../images/facebookIcon.PNG") no-repeat 5px center;
	padding-left: 40px;
	color: #303032;
}

.linkedInIcon {
	background: url("../../images/linkedInIcon.PNG") no-repeat 5px center;
	padding-left: 40px;
	color: #303032;
}

.youTubeIcon {
	background: url("../../images/youTubeIcon.PNG") no-repeat 5px center;
	padding-left: 40px;
	color: #303032;
}

.instagramIcon {
	background: url("../../images/instagramIcon.PNG") no-repeat 5px center;
	padding-left: 40px;
	color: #303032;
}

.noCardIcon {
	background: url("../../images/cardDefault.png") no-repeat 1px center;
	padding-left: 60px;
	color: #303032;
	font-weight: 600;
}

.mastercardIcon {
	background: url("../../images/masterCard.png") no-repeat 1px center;
	padding-left: 60px;
	color: #303032;
	font-weight: 600;
}

.visaIcon {
	background: url("../../images/Visa.png") no-repeat 1px center;
	padding-left: 60px;
	color: #303032;
	font-weight: 600;
}

.amexIcon {
	background: url("../../images/AMEX.png") no-repeat 1px center;
	padding-left: 60px;
	color: #303032;
	font-weight: 600;
}

.discoverIcon {
	background: url("../../images/discover.png") no-repeat 1px center;
	padding-left: 60px;
	color: #303032;
	font-weight: 600;
}

.reviewsContainer label {
	color: unset;
	font-size: unset;
}

.disabledStar {
	color: #808080 !important;
	font-size: 0.9rem !important;
	cursor: default;
}

.ratedStar {
	color: #2db784 !important;
	border-color: #96dbc0 !important;
}

.ratedStar label,
.commentRatingStar label {
	font-size: 1.4rem !important;
	color: #2db784 !important;
}

.overallRating {
	color: #2db784 !important;
	border-color: #96dbc0 !important;
	font-size: 1rem;
	font-weight: 900;
}

.ratingValue {
	font-size: 0.9rem;
	cursor: default;
}

.message :hover {
	background-color: #e5e5e5;
	cursor: pointer;
}

.messageCard {
	background-color: #ffffff !important;
	padding: 5px !important;
}

.commentRatingStar {
	font-size: 1.4rem !important;
	color: #2db784 !important;
	border-color: #96dbc0 !important;
}

.commentAuthor {
	font-size: 0.9rem;
	font-weight: 600;
}

.commentBody {
	font-size: 0.8rem;
	font-weight: 100;
	color: #848486;
}

.commentDate {
	font-size: 0.75rem;
	font-weight: 100;
	color: #adb5bd;
}

.commentReplyContainer {
	background-color: #fafafa;
	padding: 15px;
	border-radius: 5px;
}

.askforReviewsContainer {
	background-color: #fafafa;
	padding: 12px;
	border-radius: 5px;
	border: 1px solid #adb5bd;
}

.reviewRatingBar,
.reviewRatingBar0 {
	border-radius: 5px !important;
	height: 8px !important;
	background-color: #fafafa !important;
}

.reviewRatingBar span {
	border-radius: 5px !important;
	background-color: #2db784 !important;
}

.reviewRatingBar0 span {
	border-radius: 5px !important;
	background-color: transparent !important;
}

.highRatedParameter {
	font-weight: 600;
}

.subscriptionCard {
	padding: 15px;
	border: 1px solid #adb5bd;
	border-radius: 2px;
	background-color: #f8f9fa;
}

.subscriptionCard.active {
	background-color: #e4f5e8;
}

.imgCreditCard {
	width: 60px;
}

.imgPDFIcon {
	width: 20px;
}

.liHeaderActive {
	padding: 7px;
	border-radius: 5px;
	/* background-color: #c4c4c3; */
	border-bottom: 1px solid #000;
	font-weight: 600;
}

/* .profileContainer .formContainer label {
	width: 100%;
	display: flex;
} */

.custMultiControlLabel {
	flex: 1;
}

.MuiAutocomplete-root legend {
	width: auto;
	font-size: 0.9rem;
}

.autoComplete .MuiAutocomplete-inputRoot {
	height: calc(1.5em + 0.75rem + 2px);
}

.autoComplete .MuiAutocomplete-input {
	position: absolute;
	width: 85% !important;
	font-size: 0.9rem;
}

.MuiAutocomplete-popper {
	font-size: 0.9rem;
}

.projectItemHeader {
	padding-top: 1rem;
	padding-bottom: 1rem;
	background-color: #f8f9fa;
	border-radius: 5px;
}

.btnCloseDialog {
	position: absolute !important;
	right: 8px !important;
	top: 8px !important;
}

.profilePicSmall {
	width: 50px;
	height: 50px;
	border: 1px solid #c7ccd1;
	border-radius: 5px;
	text-align: center;
	padding: 5px;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
}

.profilePicLarge {
	width: 100px;
	height: 100px;
	border: 1px solid #c7ccd1;
	border-radius: 5px;
	text-align: center;
	padding: 5px;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
}

/* .licenseCardContentMaxHeight {
	 overflow: auto;
	max-height: 97px;
	min-height: 92px; 
}

.licenseCard {
	min-height: 242px;
	max-height: 242px;
	max-width: 345px;
} */
/*accordian css*/
/*Account Settings*/

/*Buttons */
.btn-cancel {
	font-size: 0.81rem;
	font-weight: bold;
	border: 1px solid #adb5bd;
}

/*Buttons */

/*Rating */
.repeatProjDetailsContainer .projRating a i.fa-star-full {
	color: #2db682;
	font-weight: bold;
	font-size: 0.8rem;
}

.repeatProjDetailsContainer .projRating a i.fa-star-full:hover {
	color: #f4f768;
	font-weight: bold;
	font-size: 0.8rem;
}

.repeatProjDetailsContainer .projRating a i.fa-star:hover {
	color: #f4f768;
	font-weight: bold;
	font-size: 0.8rem;
}

/*Rating */

.containerMinHeight {
	min-height: 71vh;
}

@media only screen and (min-width: 768px) {
	.formContainer {
		width: 31.87rem;
		background: #fff;
		padding: 3.12rem;
		border: 1px solid #9c9898;
		border-radius: 0.25rem;
		margin: auto;
		background: transparent !important;
	}

	.messageCard {
		background-color: #ffffff !important;
		padding: 5px !important;
	}

	.searchContainerForm {
		width: 50rem;
		margin: auto;
		padding-top: 23rem;
	}

	.profileContainer .formContainer {
		width: 100%;
		padding-top: 0.5rem;
		padding-left: 2.5rem;
		padding-right: 1rem;
		border: none;
		border-radius: 0;
		padding: 0;
		/* border-left-color: #9c9898;
		border-left-width: 1px;
		border-left-style: solid;		 */
		margin: auto;
	}
}
/* Small devices (landscape phones, 576px and up)*/
@media (max-width: 767px) {
	.popularServicesSection {
		/* min-height: 55vh; */
		padding-top: 3rem;
		padding-bottom: 3rem;
	}

	.becomeLayer1,
	.becomeLayer11,
	.become_growth {
		min-height: 37vh;
	}

	.about_us,
	.howToUse,
	.howItWorks {
		min-height: 47vh;
	}

	.becomeLayer3 {
		min-height: 25vh;
	}

	.becomeLayer4 {
		min-height: 55vh;
	}

	.becomeLayer5 {
		min-height: 30vh;
	}

	.becomeLayer1 .layerOne,
	.becomeLayer11 .layerOne {
		padding-top: 2rem;
	}

	.becomeLayer5 .layerOne,
	.becomeAProSection .layerOne {
		padding-top: 3rem;
	}

	.becomeAProSection {
		min-height: 30vh;
	}

	.become_layer4 {
		min-height: 20vh;
	}

	.containerMinHeight {
		min-height: 40vh;
	}

	.masterHead {
		padding: 9px 0px;
		/* background-color: #e5e5e5; */
	}

	.formContainer h3 {
		font-size: 1.3rem;
	}

	.messageCard {
		background-color: #ffffff !important;
		padding: 5px !important;
	}

	.navigationContainerResponsive .navbar {
		justify-content: flex-end;
	}
	.navigationContainerResponsive .navbar-toggler {
		margin-top: 0.5rem;
		font-size: 0.85rem;
	}

	.formContainer {
		width: 100%;
		height: 100%;
		background: #fff;
		padding: 3.12rem;
		border: 1px solid #9c9898;
		border-radius: 0.25rem;
		background: transparent !important;
	}

	.profileContainer .formContainer {
		width: 100%;
		padding: 1rem;
		border: none;
		border-radius: 0;
		padding: 0;
		/* border-left-color: #9c9898;
		border-left-width: 1px;
		border-left-style: solid; */
		margin: auto;
	}

	.footerLinksSection {
		margin-bottom: 1rem;
	}
	.projectsDetailsContainer .details {
		margin-bottom: 1rem;
	}
	.projectsDetailsContainer {
		margin-bottom: 1.5rem;
		padding-bottom: 1.5rem;
		border-bottom: 1px solid #d9dce3;
	}
	.navigationContainerResponsive .navbar-light .navbar-nav .nav-link {
		padding: 0.25rem 0;
	}
	.repeatProjDetailsContainer {
		border-bottom: 1px solid #999;
		padding-bottom: 1rem;
		margin-bottom: 1rem;
	}
	.repeatProjDetailsContainer:last-child {
		border-bottom: 0px solid #999;
	}
	.repeatInboxContainer .userImg {
		margin-bottom: 1rem;
	}
	.searchContainerForm {
		width: 95%;
		padding-top: 12rem;
		margin: auto;
	}
	.searchContainer {
		background-size: contain;
		height: auto;
	}
	.accountNavigation {
		border-bottom: 1px solid #999;
		padding-bottom: 1rem;
		margin-bottom: 1rem;
	}

	.subscriptionCard {
		margin-bottom: 1.2rem;
	}
}
@media (min-width: 768px) and (max-width: 990px) {
	.searchContainer {
		height: auto;
		background-size: 100%;
	}
	.searchContainerForm {
		width: 80%;
		margin: auto;
		padding-top: 20rem;
	}
}
@media (min-width: 1024px) and (max-width: 1439px) {
	.searchContainer {
		height: 71vh;
		background-position: center;
	}
	.searchContainerForm {
		padding-top: 24.5rem;
	}
}

@media (min-width: 1280px)  {
	.searchContainer {
		height: 87vh;
		background-position: center;
	}
}

@media (max-width: 991px) {

}

@media (min-width: 1920px) {
	.searchContainerForm {
		padding-top: 28rem;
	}
}

::-webkit-scrollbar {
	width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #f1f1f1;
	box-shadow: inset 0 0 5px grey;
	/* border-radius: 10px; */
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #999;
	/* border-radius: 5px; */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #555;
}
